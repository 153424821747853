import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import WaveTsunami from "@Assets/Icon/wave-tsunami";
import ImgLightQuestion from "@Assets/Images/question-light-img.png";
import useWindowSize from "@Hooks/useWindowSize";
import ImgWwaveTsunami from "@Assets/Images/wave-element-skills-for-education.png";
export default function MainFeatureSkillsEducation() {
  const { width } = useWindowSize();
  const dataCarousel = useMemo(() => {
    return [
      {
        id: "01",
        title: "Sistem Belajar Online",
        description: "Sekolah dapat mengakses kelas dimanapun dan kapanpun",
        image: Images.IMG_CAROUSEL_1_SKILLS_EDUCATION,
      },
      {
        id: "02",
        title: "Tracking Progress Belajar Siswa",
        description:
          "Guru dan bagian administrasi bisa melakukan pengecekan Progress belajar siswa dengan efisien",
        image: Images.IMG_CAROUSEL_2_SKILLS_EDUCATION,
      },
      {
        id: "03",
        title: "Penilaian dan Penerbitan Rapor",
        description:
          "Pihak sekolah bisa dengan mudah memberikan nilai dan penerbitan rapor siswa",
        image: Images.IMG_CAROUSEL_3_SKILLS_EDUCATION,
      },
      {
        id: "04",
        title: "Manajemen Sekolah",
        description:
          "Pihak sekolah dapat menambahkan kelas dan administrasi lainnya dengan mudah melalui LMS SKills.id",
        image: Images.IMG_CAROUSEL_4_SKILLS_EDUCATION,
      },
    ];
  }, []);

  const dataStepsForSchool = useMemo(() => {
    return [
      {
        title: "Hubungi Tim Kami",
        description:
          "Isi form hubungi kami pada halaman ini, dan tunggu tim kami untuk menghubungi Anda kembali.",
      },
      {
        title: "Demo Aplikasi",
        description:
          "Tim kami akan melakukan demo aplikasi kepada Anda, pada tahap ini kita juga bisa saling melakukan diskusi terkait kebutuhan sekolah Anda.",
      },
      {
        title: "Persetujuan Akhir",
        description:
          "Bila anda merasa cocok dan ingin menggunakan aplikasi dari skills.id, tim kami akan memberikan penawaran untuk Anda.",
      },
    ];
  }, []);

  const [activeCarousel, setActiveCarousel] = useState(dataCarousel[0]);

  const nextCarouselItem = () => {
    const currentIndex = dataCarousel.findIndex(
      (item) => item.id === activeCarousel.id
    );
    const nextIndex = (currentIndex + 1) % dataCarousel.length;
    setActiveCarousel(dataCarousel[nextIndex]);
  };

  useEffect(() => {
    const interval = setInterval(nextCarouselItem, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCarousel, dataCarousel]);

  return (
    <div className={Styles.wrapperConditionPrakerja}>
      <div className={Styles.wrapperText}>
        <h1>
          Fitur Utama <span>Skills.id for Education</span>
        </h1>
      </div>
      <div className={Styles.carouselImg}>
        <div className={Styles.left}>
          {dataCarousel.map((c) => (
            <div
              onClick={() => setActiveCarousel(c)}
              className={`${Styles.list} ${
                c.id === activeCarousel?.id && Styles.active
              }`}
              key={c.id}
            >
              <h4>{c.id}</h4>
              <h2>{c.title}</h2>
              {c.id === activeCarousel.id && <p>{c.description}</p>}
            </div>
          ))}
        </div>
        <div className={Styles.right}>
          <img src={activeCarousel.image} alt={activeCarousel.title} />
        </div>
      </div>
      <img
        src={ImgLightQuestion}
        alt="question-img"
        className={Styles.imgQuestions}
      />
      <div className={Styles.borderWave}>
        {width <= 768 ? (
          <WaveTsunami className={Styles.wave} isGradient isMobile />
        ) : (
          <img src={ImgWwaveTsunami} alt="" className={Styles.newWave} />
        )}

        <div className={Styles.viewRowBetween}>
          <div className={Styles.left}>
            <h2>Ingin Menggunakan Skills.id Untuk Sekolah Anda?</h2>
            <span>Ikuti langkah berikut ini :</span>
          </div>
          <div className={Styles.right}>
            {dataStepsForSchool.map((l, idx) => (
              <div className={Styles.viewColWithDivider} key={idx}>
                <div className={Styles.divider}></div>
                <div className={Styles.title}>
                  <div>
                    <span>{idx + 1}</span>
                  </div>
                  <h4>{l.title}</h4>
                </div>
                <p>{l.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
