import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import tokopediaLogo from "@Assets/Icon/tokopedia.svg";
import pintarLogo from "@Assets/Icon/pintar.svg";
import bukalapakLogo from "@Assets/Icon/bukalapak.svg";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";

export default function PartnerPrakerjaCard() {
  const navigate = useNavigate();
  return (
    <div className={Styles.separator}>
      <div className={Styles.containerCard}>
        <img src={Images.LEFT_CLAY} alt="clay" className={Styles.leftClay} />
        <img src={Images.RIGHT_CLAY} alt="clay" className={Styles.rightClay} />
        <div className={Styles.wrapper}>
          <h1>Temukan Pelatihan Skills.id di Mitra Prakerja Pilihanmu</h1>
          <div className={Styles.partnerPrakerja}>
            <div>
              <img src={tokopediaLogo} alt="tokopedia-logo" />
            </div>
            <div>
              <img src={pintarLogo} alt="pintar-logo" />
            </div>
            <div>
              <img src={bukalapakLogo} alt="bukalapak-logo" />
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.question}>
        <h2>Punya Pertanyaan Terkait Prakerja?</h2>
        <p>
          Lihat selengkapnya di halaman <span>FAQ</span>
        </p>
        <Button
          onClick={() => navigate("/faq")}
          variant="outlined"
          text={"Lihat Selengkapnya"}
          endIcon={"arrow-right-next"}
        />
      </div>
    </div>
  );
}
